<template>
     <a-modal
        :title="title"
        :visible="visible"
        :confirm-loading="confirmLoading"
        @cancel="$emit('update', 0)"
        @ok="handleSubmit"
        destroyOnClose
    >
      <a-form
        :form="form"
        v-bind="formItemLayout"
    >
        <a-form-item label="商品名称">
            <a-input  v-decorator="[ 'name',{initialValue: data.name, rules: [{ required: true, message: '请填写分类名称' }] },]" placeholder="请输入链接" />
        </a-form-item>
      </a-form>
    </a-modal>
</template>
<script>
import * as http from '@/libs/examapi'
export default {
    name: 'addIndexModel',
    props: [ 'visible', 'data' ],
    beforeCreate() {
        this.form = this.$form.createForm(this);
    },
    watch:{
        visible(val){
            if(val){
                if(JSON.stringify(this.data) !== '{}'){
                    this.title = "编辑商品"
                }else{
                    this.title = "新增商品"
                }
            }
        }
    },
    data(){
        return{
            confirmLoading: false,
            title: '',
            formItemLayout: {
                labelCol: { span: 5 },
                wrapperCol: { span: 18 },
            },
        }
    },
    methods:{
        handleSubmit(){
            this.form.validateFields((err, values) => {
                if(err) return;
                this.confirmLoading = true
                if(JSON.stringify(this.data) !== '{}'){
                    if(values.name != this.data.name){
                        http.editGoodsCate({...values, id: this.data.id}).then(() => {
                           this.confirmLoading = false
                           this.$emit('update', 1)
                        }).catch(err => {
                            this.confirmLoading = false
                        })
                    }else{
                        this.confirmLoading = false
                        this.$emit('update', 0)
                    }
                }else{
                    http.addGoodsCate(values).then(() => {
                       this.confirmLoading = false
                       this.$emit('update', 1)
                    }).catch(err => {
                        this.confirmLoading = false
                    })
                }
            })
        },
    }
}
</script>